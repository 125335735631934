<template>
  <div class="vjs-tech">
    <video-player
      id="video"
      class="video-player-box"
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      customEventName="customstatechangedeventname"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
    >
    </video-player>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";

import "video.js/dist/video-js.css";
export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        // aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "https://launcher-apps.oss-cn-qingdao.aliyuncs.com/EMG_Web_Videos/index.mp4", //视频url地址
          },
        ],
        // poster: require("../assets/image/Index/video01.png"), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: false,
      },
      screenWidth: document.body.offsetWidth - 17,
      videoHeight: ((document.body.offsetWidth - 17) / 1280) * 720,
    };
  },
  mounted() {
    this.$refs.videoPlayer.player.muted(true);
    let that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    sessionStorage.setItem("videoHeight", this.videoHeight);
  },
  methods: {
    onPlayerPlay(player) {
      // console.log('player play!', player)
      // console.log(this.screenWidth);
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log('player onPlayerEnded!', player)
    },
    onPlayerWaiting(player) {
      // console.log('player onPlayerWaiting!', player)
    },
    onPlayerPlaying(player) {
      // console.log('player onPlayerPlaying!', player)
    },
    onPlayerLoadeddata(player) {
      // console.log('player onPlayerLoadeddata!', player)
    },
    onPlayerTimeupdate(player) {
      // console.log('player onPlayerTimeupdate!', player)
    },
    onPlayerCanplay(player) {
      // console.log('player onPlayerCanplay!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player onPlayerCanplaythrough!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
  },
  // watch:{
  //     screenWidth(val){
  //         this.videoHeight = this.screenWidth/1280*720
  //         sessionStorage.setItem('videoHeight',this.videoHeight)

  //         const img = document.querySelector('#video')
  //         console.log(window.matchMedia);
  //         console.log(window.matchMedia('(prefers-color-scheme: dark)').matches);
  //       if (window.matchMedia &&
  //           window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //         img.style.filter="invert(100%)";
  //       }
  //     }
  // }
};
</script>

<style lang="less" scoped>
// .vjs-custom-skin > .video-js {
//   pointer-events: none
// }
.vjs-tech {
  pointer-events: none;
  width: 1920px;
  height: 802px;
}
</style>
