import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/productService",
    name: "productService",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductService.vue"),
  },
  {
    path: "/solution",
    name: "solution",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Solution.vue"),
  },
  {
    path: "/scenarios",
    name: "scenarios",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Scenarios.vue"),
  },
  {
    path: "/digitalAsset",
    name: "digitalAsset",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DigitalAsset.vue"),
  },
  {
    path: "/articleDetails",
    name: "articleDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails.vue"),
  },
  {
    path: "/articleDetails_p5",
    name: "articleDetails_p5",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p5.vue"),
  },
  {
    path: "/articleDetails_p4",
    name: "articleDetails_p4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p4.vue"),
  },
  {
    path: "/articleDetails_p3",
    name: "articleDetails_p3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p3.vue"),
  },
  {
    path: "*", // 重定向页面地址
    redirect: "/",
  },
  {
    path: "/articleDetails_p2",
    name: "articleDetails_p2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p2.vue"),
  },
  {
    path: "/articleDetails_p6",
    name: "articleDetails_p6",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p6.vue"),
  },
  {
    path: "/articleDetails_p7",
    name: "articleDetails_p7",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p7.vue"),
  },
  {
    path: "/articleDetails_p8",
    name: "articleDetails_p8",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p8.vue"),
  },
  {
    path: "/articleDetails_p9",
    name: "articleDetails_p9",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArticleDetails_p9.vue"),
  },
  {
    path: "/productdetails",
    name: "productdetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails.vue"),
  },
  {
    path: "/productdetails1",
    name: "productdetails1",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails1.vue"),
  },
  {
    path: "/productdetails2",
    name: "productdetails2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails2.vue"),
  },
  {
    path: "/productdetails3",
    name: "productdetails3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails3.vue"),
  },
  {
    path: "/productdetails4",
    name: "productdetails4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails4.vue"),
  },
  {
    path: "/productdetails5",
    name: "productdetails5",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails5.vue"),
  },
  {
    path: "/productdetails6",
    name: "productdetails6",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails6.vue"),
  },
  {
    path: "/productdetails7",
    name: "productdetails7",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails7.vue"),
  },
  {
    path: "/productdetails8",
    name: "productdetails8",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails8.vue"),
  },
  {
    path: "/productdetails9",
    name: "productdetails9",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails9.vue"),
  },
  {
    path: "/productdetails10",
    name: "productdetails10",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails10.vue"),
  },
  {
    path: "/productdetails11",
    name: "productdetails11",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails11.vue"),
  },
  {
    path: "/productdetails12",
    name: "productdetails12",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails12.vue"),
  },
  {
    path: "/productdetails13",
    name: "productdetails13",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetails13.vue"),
  },
  {
    path: "/TechnicalSupportDetails",
    name: "TechnicalSupportDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TechnicalSupportDetails.vue"
      ),
  },
  {
    path: "/TechnicalSupportDetails1",
    name: "TechnicalSupportDetails1",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TechnicalSupportDetails1.vue"
      ),
  },
  {
    path: "/TechnicalSupportDetails2",
    name: "TechnicalSupportDetails2",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TechnicalSupportDetails2.vue"
      ),
  },
  {
    path: "/NewsDetails",
    name: "NewsDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetails.vue"),
  },
  {
    path: "/NewsDetails1",
    name: "NewsDetails1",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetails1.vue"),
  },
  {
    path: "/NewsDetails2",
    name: "NewsDetails2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetails2.vue"),
  },
  {
    path: "/NewsDetails3",
    name: "NewsDetails3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetails3.vue"),
  },
  {
    path: "/NewsDetails4",
    name: "NewsDetails4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetails4.vue"),
  },
  {
    path: "/Digital_Human",
    name: "Digital_Human",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Digital_Human.vue"),
  },
  {
    path: "/IvLivePro",
    name: "IvLivePro",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/IvLivePro.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
