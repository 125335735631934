/* eslint-disable */
<template>
  <div id="container">
    <div class="f1">
      <Video class="video" ref="video"></Video>
    </div>
    <div class="f2">
      <swiper
        :options="swiperOptionF2"
        ref="swiperTop"
        class="swiper swiper-no-swiping"
      >
        <swiper-slide class="slide">
          <div class="top">虚拟现实 更近现实</div>
          <div class="xian"></div>
          <div class="text">
            无论山川、流水、云雾，或是虚空、梦幻……每个场景均可虚实相合。
            <br />
            运用虚拟现实技术，脑海中的致美场景得以细腻呈现，与现实无异。
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div class="top">中国智造 民族情节</div>
          <div class="xian"></div>
          <div class="text">
            过去几年，我们所做的事情远比想象的还多。凭借地球山强大的研发实力，我们成功突破国产虚拟现实技术新高度，在虚拟现实历史长卷中写下来自中华民族的自豪笔墨。
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div class="top">二十年安全服务 铸就品质底蕴</div>
          <div class="xian"></div>
          <div class="text">
            累计直播超70000小时，亲历行业变迁。
            <br />
            探索技术前沿发展，定位行业未来趋势，助力客户节目升华。
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" id="custom"></div>
      </swiper>
      <div class="xian2"></div>
    </div>
    <div class="f3">
      <div class="title">产品和服务</div>
      <div class="xian"></div>
      <div class="swiper-container">
        <div class="anniu-container">
          <swiper
            :options="swiperOption1"
            ref="swiperTop3"
            class="container-box swiper-no-swiping"
          >
            <swiper-slide class="box">
              <div class="top f3_box1">
                <img src="../assets/image/index/f3_1.png" alt="" />
                <div class="top-title">GBOX VS</div>
                <div class="top-text">视觉盛宴，变幻不息</div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="box">
              <div class="top f3_box2">
                <img src="../assets/image/index/f3_2.png" alt="" />
                <div class="top-title">GBOX VP</div>
                <div class="top-text">独家渲染技术，特效虚幻如实</div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="box">
              <div class="top f3_box7">
                <img src="../assets/image/index/f3_7.png" alt="" />
                <div class="top-title">技术服务和现场支持</div>
                <div class="top-text">20年如一日，0事故服务</div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <!-- <swiper-slide class="box">
                            <div class="top f3_box4">
                                <img src="../assets/image/index/f3_4.png" alt="">
                                <div class="top-title">数字人</div>
                                <div class="top-text">生命的新形态</div>
                                <div class="xiangQ">点击了解更多>></div>
                            </div>

                        </swiper-slide> -->
            <swiper-slide class="box">
              <div class="top f3_box3">
                <img src="../assets/image/index/f3_3.png" alt="" />
                <div class="top-title">iV LIVE</div>
                <div class="top-text">人人都是虚拟玩家，打造直播领域新生态</div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <!-- <swiper-slide class="box">
                            <div class="top f3_box4">
                                <img src="../assets/image/index/f3_4.png" alt="">
                                <div class="top-title">数字人</div>
                                <div class="top-text">以光驱动，与影随行</div>
                                <div class="xiangQ">点击了解更多>></div>
                            </div>
                        </swiper-slide> -->
            <!-- <swiper-slide class="box">
                            <div class="top f3_box5">
                                <img src="../assets/image/index/f3_5.png" alt="">
                                <div class="top-title">数字影楼</div>
                                <div class="top-text">前沿技术实现美好生活</div>
                                <div class="xiangQ">点击了解更多>></div>
                            </div>
                        </swiper-slide> -->
            <!-- <swiper-slide class="box">
                            <div class="top f3_box6">
                                <img src="../assets/image/index/f3_6.png" alt="">
                                <div class="top-title">数字孪生</div>
                                <div class="top-text">将现实工业映射于虚拟环境</div>
                                <div class="xiangQ">点击了解更多>></div>
                            </div>
                        </swiper-slide> -->
          </swiper>
          <img
            class="swiper-prev btns"
            @click="prev('swiperTop3')"
            src="../assets/image/index/left.png"
            alt=""
          />
          <img
            class="swiper-next btns"
            @click="next('swiperTop3')"
            src="../assets/image/index/right.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="f4">
      <div class="container">
        <swiper
          :options="swiperOptionF4"
          ref="swiperTop"
          class="swiper swiper-no-swiping"
        >
          <swiper-slide class="slide f4_box1">
            <div class="title">GBOX VS</div>
            <div class="text">超写实虚拟制播系统</div>
            <div class="xian"></div>
            <div class="text-bottom">
              使用原生Unreal Engine，支持Epic官方插件自更新。<br />具备高品质光影技术、丰富特效制作工具以及令人难以割舍的交互特性。
            </div>
            <img src="../assets/image/index/f4_img.png" alt="" />
            <div class="xiangQ">了解更多>></div>
          </swiper-slide>
          <swiper-slide class="slide f4_box2">
            <div class="title">GBOX VP</div>
            <div class="text">单一空间，千变境界</div>
            <div class="xian"></div>
            <div class="text-bottom">
              内置多元模块，一机即可满足虚拟节目全链路制作需求。
            </div>
            <img src="../assets/image/index/f4_img_2.png" alt="" />
            <div class="xiangQ">了解更多>></div>
          </swiper-slide>
          <div
            class="swiper-pagination-f4"
            slot="pagination"
            id="customF4"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="f5">
      <div class="title">解决方案</div>
      <div class="xian"></div>
      <div class="swiper-container">
        <div class="anniu-container">
          <swiper
            :options="swiperOption5"
            ref="swiperTop4"
            class="container-box swiper-no-swiping"
          >
            <swiper-slide class="boxs">
              <div class="box f5_box1">
                <img src="../assets/image/index/f5_1.png" alt="" />
                <div class="box-title">CCTV 央视春晚直播</div>
                <div class="box-text">
                  地球山团队运用体积雾技术呈现近乎自然光线的舞台场景效果。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f5_box2">
                <img src="../assets/image/index/f5_2.png" alt="" />
                <div class="box-title">CCTV 央视冬奥会</div>
                <div class="box-text">
                  地球山为北京冬奥会开、闭幕式现场和央视专题直播提供技术支持。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f5_box3">
                <img src="../assets/image/index/f5_3.png" alt="" />
                <div class="box-title">CGTN 宇航大会新闻报道</div>
                <div class="box-text">
                  地球山GBOX
                  VS-实时渲染系统为国际宇航大会提供先进的虚拟现实技术支持。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f5_box4">
                <img src="../assets/image/index/f5_4.png" alt="" />
                <div class="box-title">安徽卫视春节联欢晚会</div>
                <div class="box-text">
                  地球山作为主创团队，为晚会虚拟植入提供AR图形设计制作与全程技术保障。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f5_box5">
                <img src="../assets/image/index/f5_5.png" alt="" />
                <div class="box-title">CCTV 央视欧洲杯直播报道</div>
                <div class="box-text">
                  地球山技术团队为卡塔尔世界杯专题节目《足球盛宴》提供技术支持。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f5_box6">
                <img src="../assets/image/index/f5_6.png" alt="" />
                <div class="box-title">央视三星堆考古现场直播</div>
                <div class="box-text">
                  地球山技术团队为央视《三星堆新发现》直播特别节目提供技术支持。
                </div>
                <div class="xiangQ">点击了解更多>></div>
              </div>
            </swiper-slide>
          </swiper>
          <img
            class="swiper-prev btns"
            @click="prev('swiperTop4')"
            src="../assets/image/index/left.png"
            alt=""
          />
          <img
            class="swiper-next btns"
            @click="next('swiperTop4')"
            src="../assets/image/index/right.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="f6">
      <div class="title">关于地球山</div>
      <div class="xian"></div>
      <div class="text">
        地球山是一家中国虚拟现实科技公司，致力于为全球用户提供全生命周期的技术服务体系和专业软硬件系统解决方案。
        <br />
        <br />
        地球山是将虚拟演播室带入中国的技术团队，为央视(CCTV)和地方卫视提供专业的虚拟现实产品和技术支持服务，连续多年作为主创参与央视春晚，世界杯、奥运会、冬奥会直播等多个国际级节目。
        <br />
        <br />
        地球山于2015年开始自主研发，打造XR沉浸式渲染系统 “GBOX VS”；
        4K/8K超高清全媒体智能演播室系统“GBOX VP”；拥有实时三维染引擎的“GBOX VP
        全媒体虚拟演播一体机”。 <br />
      </div>
      <div class="imgs">
        <img src="../assets/image/index/f6_1.png" alt="" />
        <img src="../assets/image/index/f6_2.png" alt="" />
        <img src="../assets/image/index/f6_3.png" alt="" />
      </div>
    </div>
    <!-- <div class="f7">
            <div class="title">数字资产</div>
            <div class="xian"></div>
            <div class="swiper-container">
                <div class="anniu-container">
                    <swiper :options="swiperOption2" ref="swiperTop5" class="container-box swiper-no-swiping">
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_1.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">虚拟直播间</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_2.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">线上发布会</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_3.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">赛事比分图</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_4.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">动感方块裸眼3D</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_5.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">魔方金属创意</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="boxs">
                            <div class="box">
                                <img src="../assets/image/index/f7_6.png" alt="">
                                <div class="box-main">
                                    <div class="box-title">体育直播图</div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_zan.png" alt="">
                                        <span>6.7w</span>
                                    </div>
                                    <div class="zan">
                                        <img src="../assets/image/index/f7_download.png" alt="">
                                        <span>980</span>
                                    </div>
                                </div>
                                <div class="box-bottom">

                                适用场景：新闻、直播
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <img class="swiper-prev btns"  @click="prev('swiperTop5')" src="../assets/image/index/left.png" alt="">
                    <img class="swiper-next btns" @click="next('swiperTop5')" src="../assets/image/index/right.png" alt="">
                </div>
            </div>
            <div class="btn">
                <img src="../assets/image/index/btn.png" alt="">
                <span>查看更多</span>
            </div>
        </div> -->
    <div class="f8">
      <div class="title">新闻动态</div>
      <div class="xian"></div>
      <div class="swiper-container">
        <div class="anniu-container">
          <swiper
            :options="swiperOptionF8"
            ref="swiperTop6"
            class="container-box swiper-no-swiping"
          >
            <swiper-slide class="boxs">
              <div class="box f8_box1">
                <img src="../assets/image/index/f8_1.png" alt="" />
                <div class="box-right">
                  <div class="right-title">
                    持续十六年，地球山助力2023央视春晚AR精彩呈现
                  </div>
                  <div class="right-text">
                    2022年11月29日，地球山技术团队就已入驻2023年央视春晚筹备现场，开始为中央广播电视总台《2023年春节联欢晚会》提供包含设备安装调试、技术保障、虚拟场景制作在内的直播技术支持，确保春晚安全直播和高品质视觉呈现。此次技术服务也是地球山自2008年首次参与央视春晚直播以来连续16年为央视春晚直播提供技术支持。
                  </div>
                  <div class="right-xiangQ">了解详情>></div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f8_box2">
                <img src="../assets/image/index/f8_2.png" alt="" />
                <div class="box-right">
                  <div class="right-title">
                    地球山助力卡塔尔世界杯专题节目直播
                  </div>
                  <div class="right-text">
                    2022卡塔尔世界杯于2022年11月20日开幕，早在开幕前三天，地球山技术团队就已入驻到中央电视台体育频道，为卡塔尔世界杯专题节目《足球盛宴》提供设备安装调试，确保播出的安全流畅和视觉呈现。
                  </div>
                  <div class="right-xiangQ">了解详情>></div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="boxs">
              <div class="box f8_box3">
                <img src="../assets/image/index/f8_3.png" alt="" />
                <div class="box-right">
                  <div class="right-title">
                    地球山助力冬奥会开闭幕式和专题直播
                  </div>
                  <div class="right-text">
                    2022年2月4日，随着冬季奥林匹克运动会正式开幕，北京再次成为世界瞩目的焦点。在北京冬奥会举办之际，地球山为冬奥会开、闭幕式现场和专题直播提供技术支持，助力冬奥会以更精彩的呈现形式亮相。
                  </div>
                  <div class="right-xiangQ">了解详情>></div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <img
            class="swiper-prev btns"
            @click="prev('swiperTop6')"
            src="../assets/image/index/left.png"
            alt=""
          />
          <img
            class="swiper-next btns"
            @click="next('swiperTop6')"
            src="../assets/image/index/right.png"
            alt=""
          />
        </div>
      </div>
      <div class="btn" @click="toPath('/scenarios')">
        <img src="../assets/image/index/btn.png" alt="" />
        <span>更多新闻</span>
      </div>
    </div>
  </div>
</template>

<script>
import Video from "@/components/Video.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: { Video, swiper, swiperSlide },
  data() {
    return {
      swiperOptionF2: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplayDisableOnInteraction: false,
        autoplay: {
          autoplay: true,
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        loopedSlides: 1,
        speed: 1000,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        rewind: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      swiperOptionF4: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplayDisableOnInteraction: false,
        autoplay: {
          autoplay: true,
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        loopedSlides: 1,
        speed: 1000,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        rewind: true,
        pagination: {
          el: ".swiper-pagination-f4",
          clickable: true, //允许分页点击跳转
        },
      },
      swiperOptionF8: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplayDisableOnInteraction: false,
        autoplay: false,
        loop: true,
        loopedSlides: 1,
        speed: 1000,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        rewind: true,
        // pagination: {
        //     el: ".swiper-paginationF8",
        //     clickable: true //允许分页点击跳转
        // }
      },
      swiperOption5: {
        slidesPerView: 3,
        spaceBetween: 84,
        autoplayDisableOnInteraction: false,
        autoplay: false,
        loop: true,
        loopFillGroupWithBlank: true,
        speed: 1500,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption1: {
        slidesPerView: 3,
        spaceBetween: 82,
        autoplayDisableOnInteraction: false,
        autoplay: false,
        loop: true,
        loopFillGroupWithBlank: true,
        speed: 1500,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        // rewind:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption2: {
        slidesPerView: 3,
        spaceBetween: 20,
        autoplayDisableOnInteraction: false,
        autoplay: false,
        loop: true,
        loopFillGroupWithBlank: true,
        speed: 1500,
        freeModeMomentumBounceRatio: 0,
        slidesPerGroup: 1,
        // rewind:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiperTop3.$swiper, this.$refs.swiperTop4.$swiper;
    },
  },
  mounted() {
    this.go("/productService", "f1", "f3_box1");
    this.go("/productService", "f2", "f3_box2");
    this.go("/productService", "f3", "f3_box3");
    this.go("/Digital_Human", null, "f3_box4");
    this.go("/productService", "f5", "f3_box5");
    this.go("/productService", "f6", "f3_box6");
    this.go("/productService", "f7", "f3_box7");
    this.go("/productService", "f1", "f4_box1");
    this.go("/productService", "f2", "f4_box2");

    this.go("/articleDetails_p8", null, "f5_box1");
    this.go("/NewsDetails3", null, "f5_box2");
    this.go("/articleDetails_p3", null, "f5_box3");
    this.go("/articleDetails_p2", null, "f5_box4");
    this.go("/articleDetails_p5", null, "f5_box5");
    this.go("/articleDetails_p7", null, "f5_box6");

    this.go("/articleDetails_p8", null, "f8_box1");
    this.go("/NewsDetails4", null, "f8_box2");
    this.go("/NewsDetails3", null, "f8_box3");
  },
  methods: {
    prev(e) {
      this.$refs[e].swiper.slidePrev();
    },
    next(e) {
      this.$refs[e].swiper.slideNext();
    },
    toPath(path, id) {
      localStorage.setItem("anchorId", id);
      // localStorage.setItem("swiperId", span_id);
      if (this.$route.path == "/productService") {
        this.$bus.$emit("toID", id);
      } else {
        this.$router.push({ path: path });
      }
      // this.showSwiper(span_id)
    },
    go(path, id, cla) {
      let _this = this;
      let box3 = document.getElementsByClassName(cla);
      for (let i = 0; i < box3.length; i++) {
        box3[i].onclick = function () {
          _this.toPath(path, id);
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 500px) {
  .f2 {
    width: 30rem;
    height: 12.68rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .swiper {
      width: 30rem;
      height: 12.68rem;
      .slide {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .swiper-pagination {
        // margin-top: 49px;
        bottom: 41px;
        ::v-deep .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 0 11px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background: #000 !important;
        }
      }
    }
  }
}
.btns {
  transition: all 0.2s;
}
.btns:hover {
  opacity: 0.8;
}
.btns:active {
  opacity: 0.6;
}
#container {
  background: #f0f0f0;
  width: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .f1 {
    margin-top: 80px;
    width: 100%;
    min-width: 1200px;
    height: 800px;
    position: relative;
    overflow: hidden;
    .video {
      position: absolute;
      width: 1920px;
      height: 800px;
      top: 0;
      left: 50%;
      margin-left: -960px;
    }
  }
  .f2 {
    width: 1200px;
    height: 402px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border-bottom: 1px solid #666;
    .swiper {
      width: 1200px;
      height: 402px;
      overflow: hidden;
      .slide {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .swiper-pagination {
        // margin-top: 49px;
        bottom: 41px;
        ::v-deep .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 0 11px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background: #000 !important;
        }
      }
    }

    .top {
      font-size: 58px;
      margin-top: 99px;
      line-height: 58px;
      color: #323232;
      font-family: "PingFangMD" !important;
      // font-weight: 500;
    }
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 35px;
    }
    .xian2 {
      width: 1200px;
      height: 1px;
      background: #898989;
      // margin-top: 41px;
    }
    .text {
      margin-top: 40px;
      text-align: center;
      font-size: 21px;
      line-height: 32px;
      font-weight: 400;
      color: #323232;
    }
    .tabs {
      margin-top: 49px;
      width: 72px;
      height: 9px;
      display: flex;
      justify-content: space-between;
      .tab {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #000;
      }
    }
  }
  .f3 {
    min-width: 1215px;
    max-width: 1639px;
    width: 100%;
    height: 921px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 29px;
    }
    .title {
      margin-top: 99px;
      font-size: 44px;
      color: #323232;
      font-family: "PingFangFW" !important;
    }
    .swiper-container {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1639px;
      min-width: 1215px;
    }
    .anniu-container {
      width: 1215px;
      position: relative;
      .swiper-prev {
        width: 77px;
        height: 150px;
        position: absolute;
        z-index: 99;
        cursor: pointer;
        left: -207px;
        top: 260px;
      }
      .swiper-next {
        width: 77px;
        height: 150px;
        cursor: pointer;
        position: absolute;
        z-index: 99;
        right: -207px;
        top: 260px;
      }
    }
    .container-box {
      margin-top: 70px;
      width: 1200px;
      display: flex;
      .box {
        width: 343px;
        height: 499px;
        display: flex;
        flex-direction: column;
        .top {
          cursor: pointer;
          box-shadow: 5px 5px 5px rgba(60, 60, 60, 0.3);
          width: 343px;
          height: 489px;
          flex-shrink: 0;
          img {
            width: 343px;
            height: 312px;
          }
          .top-title {
            margin-top: 30px;
            // font-weight: bold;
            font-family: "PingFangBOLD" !important;
            text-align: center;
            font-size: 30px;
            line-height: 30px;
            font-weight: bold;
            color: #232323;
          }
          .top-text {
            font-size: 15px;
            text-align: center;
            line-height: 15px;
            font-family: "PingFangBOLD" !important;
            margin-top: 21px;
            color: #65655b;
          }
          .xiangQ {
            font-size: 12px;
            line-height: 12px;
            text-align: end;
            cursor: pointer;
            padding-right: 18px;
            margin-top: 36px;
            color: #65655b;
          }
        }
        .bottom {
          flex-shrink: 0;
          .bottom-title {
            margin-top: 37px;
            font-size: 30px;
            text-align: center;
            font-family: "PingFangFW" !important;
            color: #232323;
            line-height: 30px;
          }
          .bottom-text {
            font-size: 15px;
            line-height: 19px;
            margin-top: 23px;
            padding: 0 5px;
            font-family: "PingFangMD" !important;
            text-align: center;
          }
        }
      }
    }
  }
  .f4 {
    width: 100%;
    min-width: 1200px;
    height: 1041px;
    background-image: url(../assets/image/index/f4_bg.png);
    background-size: 100% 100%;
    position: relative;
    // 背景图片位置
    // background-position: right;
    // 背景图片是否重复
    // background-repeat: no-repeat;
    background-color: #000;
    display: flex;
    justify-content: center;
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      // margin-top: 25px;
    }
    .swiper {
      width: 1200px;
      height: 1041px;
      .slide {
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
      .swiper-pagination-f4 {
        position: absolute;
        bottom: 75px;
        display: flex;
        z-index: 10;
        justify-content: center;
        ::v-deep .swiper-pagination-bullet {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          margin: 0 11px;
          background: #fff;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background: #aaa !important;
        }
      }
    }
    .title {
      margin-top: 130px;
      font-size: 44px;
      line-height: 44px;
      color: #ffffff;
      background-image: -webkit-linear-gradient(left, #197b3d, #19a7b0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "PingFangFW" !important;
    }
    .text {
      margin-top: 23px;
      color: #ebebeb;
      font-size: 24px;
      line-height: 24px;
    }
    .xian {
      margin-top: 24px;
      // margin-bottom: 23px;
      background-color: #898989;
    }
    .xiangQ {
      // margin-top: 24px;
      width: 1016px;
      font-size: 19px;
      line-height: 19px;
      font-weight: bold;
      color: #b4b4b4;
      cursor: pointer;
      text-align: right;
      cursor: pointer;
      margin-top: 97px;
    }
    img {
      margin-top: 69px;
      width: 1008px;
      height: 439px;
    }
    .text-bottom {
      font-size: 19px;
      color: #ebebeb;
      margin-top: 23px;
      text-align: center;
    }
  }
  .f5 {
    min-width: 1215px;
    width: 100%;
    height: 952px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 129px;
      font-size: 44px;
      color: #232323;
      font-family: "PingFangFW" !important;
      line-height: 44px;
    }
    .swiper-container {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1639px;
      min-width: 1215px;
    }
    .anniu-container {
      width: 1215px;
      position: relative;
      .swiper-prev {
        width: 77px;
        height: 150px;
        cursor: pointer;
        position: absolute;
        z-index: 99;
        left: -207px;
        top: 182px;
      }
      .swiper-next {
        width: 77px;
        height: 150px;
        cursor: pointer;
        position: absolute;
        z-index: 99;
        right: -207px;
        top: 182px;
      }
    }
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 32px;
    }
    .container-box {
      margin-top: 70px;
      width: 1200px;
      display: flex;
      .boxs {
        width: 343px;
        height: 499px;
        display: flex;
        flex-direction: column;
        .box {
          width: 343px;
          height: 489px;
          flex-shrink: 0;
          cursor: pointer;
          box-shadow: 0 5px 5px 3px rgba(60, 60, 60, 0.3);
          img {
            width: 343px;
            height: 312px;
          }
          .box-title {
            margin-top: 30px;
            font-weight: bold;
            padding: 0 25px;
            font-size: 24px;
            line-height: 24px;
            // text-overflow: ellipsis;
            white-space: nowrap;
            width: 343px;
            height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000;
          }
          .box-text {
            height: 42px;
            margin-top: 21px;
            padding: 0 28px;
            text-align: justify;
            color: #65655b;
            font-size: 15px;
            line-height: 21px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            text-overflow: -webkit-ellipsis-lastline;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .xiangQ {
            font-size: 12px;
            text-align: end;
            margin-top: 15px;
            padding: 0 28px;
            cursor: pointer;
            color: #65655b;
          }
        }
      }
    }
  }
  .f6 {
    width: 100%;
    min-width: 1200px;
    height: 1059px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #040000;
    .title {
      font-size: 44px;
      line-height: 44px;
      margin-top: 129px;
      color: #fff;
      font-family: "PingFangFW" !important;
    }
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 30px;
    }
    .text {
      margin-top: 69px;
      width: 1200px;
      font-size: 18px;
      text-align: justify;
      color: #b1b1b1;
      line-height: 30px;
    }
    .imgs {
      margin-top: 52px;
      display: flex;
      width: 1200px;
      justify-content: space-between;
      img {
        width: 394px;
        height: 243px;
        flex-shrink: 0;
      }
    }
  }
  .f7 {
    min-width: 1200px;
    max-width: 1639px;
    width: 100%;
    height: 843px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 130px;
      color: #232323;
      font-size: 44px;
      line-height: 44px;
      font-family: "PingFangFW" !important;
    }
    .swiper-container {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1639px;
      min-width: 1200px;
    }
    .anniu-container {
      width: 1200px;
      position: relative;
      .swiper-prev {
        width: 77px;
        height: 150px;
        position: absolute;
        cursor: pointer;
        z-index: 99;
        left: -207px;
        top: 142px;
      }
      .swiper-next {
        width: 77px;
        height: 150px;
        position: absolute;
        cursor: pointer;
        z-index: 99;
        right: -207px;
        top: 142px;
      }
    }
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 30px;
    }
    .container-box {
      margin-top: 70px;
      width: 1200px;
      display: flex;
      .boxs {
        // margin-top: 70px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .box {
          width: 386px;
          height: 314px;
          display: flex;
          margin-bottom: 60px;
          flex-direction: column;
          img {
            width: 386px;
            height: 240px;
          }
          .box-main {
            display: flex;
            align-items: flex-end;
            height: 74px;

            .box-title {
              font-size: 20px;
              color: #232323;
              font-weight: bold;
              line-height: 20px;
              flex: 1;
            }
            .zan {
              margin-left: 18px;
              img {
                width: 23px;
                height: 20px;
                margin-right: 6px;
                margin-bottom: -2px;
              }
              span {
                color: #333;
                font-size: 18px;
                line-height: 18px;
                font-weight: bold;
              }
            }
          }
          .box-bottom {
            margin-top: 14px;
            color: #606060;
            font-size: 14px;
            line-height: 14px;
            font-family: "PingFangMD" !important;
          }
        }
      }
    }
    .btn {
      margin-top: 47px;
      width: 228px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      font-family: "PingFangMD" !important;
      border: 2px solid #b0b0b0;
      font-size: 25px;
      color: #333333;
      font-weight: 100;
      img {
        width: 47px;
        height: 24px;
        margin-right: 17px;
      }
      span {
        font-size: 25px;
      }
    }
  }
  .f8 {
    min-width: 1215px;
    max-width: 1639px;
    width: 100%;
    height: 810px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 100px;
      font-size: 44px;
      line-height: 44px;
      color: #232323;
      font-family: "PingFangFW" !important;
    }
    .swiper-container {
      display: flex;
      justify-content: center;
      width: 100%;
      // max-width: 1639px;
      min-width: 1200px;
    }
    .xian {
      width: 48px;
      height: 2px;
      background: #898989;
      margin-top: 30px;
    }
    .anniu-container {
      width: 1200px;
      position: relative;
      .swiper-prev {
        width: 77px;
        height: 150px;
        position: absolute;
        z-index: 99;
        cursor: pointer;
        left: -219px;
        top: 152px;
      }
      .swiper-next {
        width: 77px;
        height: 150px;
        cursor: pointer;
        position: absolute;
        z-index: 99;
        right: -219px;
        top: 152px;
      }
    }
    .container-box {
      margin-top: 70px;
      width: 1200px;
      display: flex;
      .boxs {
        // margin-top: 70px;
        width: 1200px;
        display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .box {
          cursor: pointer;
          display: flex;
          margin-bottom: 10px;
          img {
            width: 396px;
            height: 245px;
            margin-right: 59px;
          }
          .box-right {
            width: 745px;
            .right-title {
              font-size: 28px;
              line-height: 28px;
              color: #323232;
              font-family: "PingFangBOLD" !important;
            }
            .right-text {
              color: #323232;
              margin-top: 40px;
              font-size: 19px;
              text-align: justify;
              line-height: 32px;
            }
            .right-xiangQ {
              margin-top: 77px;
              font-size: 17px;
              width: 100%;
              cursor: pointer;
              text-align: end;
              color: #646464;
            }
          }
        }
      }
    }
    .btn {
      margin-top: 89px;
      width: 228px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      font-family: "PingFangMD" !important;
      border: 2px solid #b0b0b0;
      font-size: 25px;
      color: #333333;
      font-weight: 100;
      img {
        width: 47px;
        height: 24px;
        margin-right: 17px;
      }
      span {
        font-size: 25px;
      }
    }

    // .box {
    //     display: flex;
    //     margin-bottom: 102px;
    //     img {
    //         width: 396px;
    //         height: 245px;
    //         margin-right: 77px;
    //     }
    //     .box-right{
    //         width: 727px;
    //         .right-title {
    //             font-size: 43px;
    //             line-height: 43px;
    //             color: #232323;
    //             font-family: 'PingFangFW' !important;
    //         }
    //         .right-text {
    //             color: #232323;
    //             margin-top: 44px;
    //             font-size: 19px;
    //             line-height: 32px;
    //         }
    //         .right-xiangQ {
    //             margin-top: 55px;
    //             font-size: 18;
    //             width: 100%;
    //             text-align: end;
    //             color: #65655B;
    //         }
    //     }
    // }
  }
}
</style>
