<template>
  <div id="app" class="app">
    <template>
      <el-backtop
        :visibility-height="2600"
        :bottom="132"
        :right="90"
        style="background: none"
      >
        <img style="background: none" src="./assets/image/gengduo.png" />
      </el-backtop>
    </template>
    <div id="daohang" class="nav" :style="{ top: active ? '0' : '-134px' }">
      <div class="nav-top">
        <img src="./assets/image/logo.png" @click="goIndex" class="left" />
        <nav id="nav">
          <div class="nav-top">
            <div class="navtab" @mouseover="navBottomHidden" @click="goTo('/')">
              <span>{{ $t("message.phrase.home") }}</span>
            </div>
            <div
              class="navtab"
              @mouseover="navBottomShow"
              @click="goTo('/productService')"
            >
              <span>{{ $t("message.phrase.products") }}</span>
            </div>
            <div
              class="navtab"
              @mouseover="navBottomHidden"
              @click="goTo('/solution')"
            >
              <span>{{ $t("message.phrase.solutions") }}</span>
            </div>
            <!-- <div @click="/scenarios">{{ $t("message.phrase.applications") }}</div> -->
            <div
              class="navtab"
              @mouseover="navBottomHidden"
              @click="goTo('/scenarios')"
            >
              <span>{{ $t("message.phrase.news") }}</span>
            </div>
            <div
              class="navtab"
              @mouseover="navBottomHidden"
              @click="goTo('/about')"
            >
              <span>{{ $t("message.phrase.about") }}</span>
            </div>
            <!-- <div class="navtab" @mouseover="navBottomHidden"><span>数字资产</span></div> -->
            <!-- <div @click="/digitalAsset">{{ $t("message.phrase.store") }}</div> -->
            <!-- <a @mouseover="navBottomHidden" v-show="langs" :default-value="options.value" @click="changeLanguage('en')">EN</a> -->
            <!-- <a @mouseover="navBottomHidden" v-show="!langs" :default-value="options.value" @click="changeLanguage('cn')">CH</a> -->
            <!-- <a class="login" @mouseover="navBottomHidden" style="text-decoration:underline;">{{ $t("message.phrase.login") }}</a> -->
          </div>
        </nav>
      </div>
      <div
        @mouseover="navBottomShow"
        @mouseout="navBottomHidden"
        class="nav-bottom"
        :style="{ height: isShow ? '290px' : 0 }"
      >
        <div class="nav-tabs" id="VirtualSpace">
          <div class="top small-tabs" @click="toPath('/productService', 'f1')">
            {{ $t("message.tabs.virtual_space") }}
          </div>
          <div class="bottom">
            <span class="small-tabs" @click="$router.push('/ProductDetails')">{{
              $t("message.tabs.XR_Set")
            }}</span>
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails1')"
              >{{ $t("message.tabs.AR_Set") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails2')"
              >{{ $t("message.tabs.VR_Set") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails4')"
              >{{ $t("message.tabs.VPW") }}</span
            >
            <!-- <span class="small-tabs">{{ $t("message.tabs.Play_Controller") }}</span> -->
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails3')"
              >{{ $t("message.tabs.Cloud_Rendering") }}</span
            >
          </div>
        </div>
        <div class="nav-tabs" id="VisionPalace">
          <div class="top small-tabs" @click="toPath('/productService', 'f2')">
            {{ $t("message.tabs.Vision_Palace") }}
          </div>
          <div class="bottom">
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails9')"
              >{{ $t("message.tabs.On_Air_Graphics") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails11')"
              >{{ $t("message.tabs.Video_Wall_Graphics") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails10')"
              >{{ $t("message.tabs.AR_Set1") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails6')"
              >{{ $t("message.tabs.VR_Set1") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails12')"
              >{{ $t("message.tabs.Engine") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails8')"
              >{{ $t("message.tabs.Ultra_Designer") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails5')"
              >{{ $t("message.tabs.Play_Controller1") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails13')"
              >{{ $t("message.tabs.All_in_One_Box") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('/ProductDetails7')"
              >{{ $t("message.tabs.Cloud_Rendering1") }}</span
            >
          </div>
        </div>
        <div class="nav-tabs" id="TechnicalSupport">
          <div class="top small-tabs" @click="toPath('/productService', 'f7')">
            {{ $t("message.tabs.Technical_Support") }}
          </div>
          <div class="bottom">
            <span
              class="small-tabs"
              @click="$router.push('TechnicalSupportDetails1')"
              >{{ $t("message.tabs.Events_and_Programs_Support") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('TechnicalSupportDetails')"
              >{{ $t("message.tabs.Graphics_Creation_Service") }}</span
            >
            <span
              class="small-tabs"
              @click="$router.push('TechnicalSupportDetails2')"
              >{{ $t("message.tabs.Technical_Operation_Service") }}</span
            >
          </div>
        </div>
        <!-- <div class="nav-tabs" id="DigitalHuman" >
            <div class="top small-tabs" @click="toPath('/productService','f4')">{{ $t("message.tabs.Digital_Human") }}</div>
            <div class="bottom">
              <span class="small-tabs" @click="toPath('Digital_Human','f2')">{{ $t("message.tabs.Product_concept") }}</span>
              <span class="small-tabs" @click="toPath('Digital_Human','f3')">{{ $t("message.tabs.Core_technology") }}</span>
              <span class="small-tabs" @click="toPath('Digital_Human','f4')">{{ $t("message.tabs.Application_value") }}</span>
            </div>
          </div> -->

        <!-- <div class="nav-tabs" id="DigitalPhotography">
            <div class="top small-tabs" @click="toPath('/productService','f5')">{{ $t("message.tabs.Digital_Photography") }}</div>
            <div class="bottom">
            </div>
          </div> -->
        <!-- <div class="nav-tabs" id="Digital_twinning" >
            <div class="top small-tabs" @click="toPath('/productService','f6')">{{ $t("message.tabs.Digital_twinning") }}</div>
            <div class="bottom">
            </div>
          </div> -->
      </div>
    </div>
    <router-view />
    <div id="footer">
      <div class="logo">
        <img src="./assets/image/logo.png" alt="" />
      </div>
      <div class="main">
        <div class="left">
          <div class="left-top">地球山（北京）科技有限公司</div>
          <div class="left-main">
            <div>北京总部：北京市朝阳区望京利泽中一路1号博雅国际中心A座902</div>
            <div>南京分部：江苏省南京市雨花台区宁双路19号云密城J栋208</div>

            <div>
              咨询热线：刘先生 13390775478 &nbsp; 傅女士 13917320109 &nbsp; 固话
              010-84782599
            </div>
          </div>
        </div>
        <div class="middo">
          <div>
            <span>姓名：</span>
            <input type="text" />
          </div>
          <div>
            <span>联系：</span>
            <input type="text" />
          </div>
        </div>
        <div class="right">
          <span>留言：</span>
          <textarea cols="30" rows="4"></textarea>
        </div>
      </div>
      <div class="footer-nav">
        <div class="navs">
          <div class="navtab" @click="goTo('/')">
            <span>{{ $t("message.phrase.home") }}</span>
          </div>
          <div class="navtab" @click="goTo('/productService')">
            <span>{{ $t("message.phrase.products") }}</span>
          </div>
          <div class="navtab" @click="goTo('/solution')">
            <span>{{ $t("message.phrase.solutions") }}</span>
          </div>
          <!-- <div @click="/scenarios">{{ $t("message.phrase.applications") }}</div> -->
          <div class="navtab" @click="goTo('/scenarios')">
            <span>{{ $t("message.phrase.news") }}</span>
          </div>
          <div class="navtab" @click="goTo('/about')">
            <span>{{ $t("message.phrase.about") }}</span>
          </div>
          <!-- <div class="navtab" @click="/about"><span>数字资产</span></div> -->
        </div>
        <div class="imgs">
          <div class="wx">
            <img class="wx-rem" src="./assets/image/wx-rem.png" alt="" />
            <img class="icon" src="./assets/image/wx.png" alt="" />
          </div>
          <div class="bili">
            <img class="bili-rem" src="./assets/image/bili-rem.png" alt="" />
            <img class="icon" src="./assets/image/bili.png" alt="" />
          </div>
          <div class="dy">
            <img class="dy-rem" src="./assets/image/dy-rem.png" alt="" />
            <img class="icon" src="./assets/image/dy.png" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >京ICP备19017427号-1</a
        >
        Copyright © 2022 EarthMountain-Tech. 保留所有权利
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langValue: "",
      active: true,
      scrollTop: 0,
      langs: true,
      isShow: false,
      options: [
        {
          value: "cn",
          label: "chinese",
        },
        {
          value: "en",
          label: "english",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  created() {
    this.langValue =
      sessionStorage.lang == undefined ? "cn" : sessionStorage.lang;
  },
  methods: {
    open() {
      window.open("https://www.iv-live.com/");
    },
    goTo(path) {
      if (path != this.$route.path) {
        this.$router.push(path);
      }
    },
    goIndex() {
      // console.log(this.$route);
      if (this.$route.path == "/") {
        window.scrollTo(0, 0);
      } else {
        this.$router.push("/");
      }
    },
    toPath(path, id, span_id) {
      localStorage.setItem("anchorId", id);
      // localStorage.setItem("swiperId", span_id);
      if (this.$route.path == "/productService" && path == "/productService") {
        this.$bus.$emit("toID", id);
      } else {
        this.$router.push({ path: path });
        this.$bus.$emit("toID", id);
      }
      // this.showSwiper(span_id)
    },
    scrollEvent() {
      this.scrollTop = window.pageYOffset;
    },
    changeLanguage(e) {
      this.langs = !this.langs;
      sessionStorage.setItem("lang", e);
      this.$i18n.locale = e;
    },
    navBottomShow() {
      this.isShow = true;
      // console.log(111111111);
    },
    navBottomHidden() {
      this.isShow = false;
      // console.log(222222222);
    },
  },
};
</script>
<style lang="less">
@import "./assets/font/font.less";
#app {
  background: #f0f0f0;
  // font-family: 'PingFang' !important;
}
.small-tabs {
  transition: color 0.2s;
  cursor: pointer;
}
.small-tabs:hover {
  color: #d9d9d9;
}
.nav {
  background-color: #232323;
  user-select: none;
  transition: top 0.5s, background 1s;
  // transition: background 2s;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    img {
      cursor: pointer;
    }
  }
  .nav-bottom {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0;
    transition: height 0.3s;
    .nav-tabs {
      display: flex;
      flex-direction: column;
      margin-right: 70px;
      .top {
        font-size: 17px;
        line-height: 17px;
        margin-bottom: 18px;
        color: #ffffff;
        font-family: "PingFangFW" !important;
        transition: color 0.2s;
      }
      .top:hover {
        color: #d9d9d9;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        color: #e4e4e4;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .left {
    width: 163px;
    height: 51px;
    margin-left: 89px;
    flex-shrink: 0;
  }
  nav {
    flex-shrink: 0;
    margin-right: 90px;
    .login {
      width: 45px;
      height: 27px;
      border: 1px solid #b8b8b8;
      border-radius: 6px;
      margin-left: 47px;
      font-size: 20px;
      color: #ebebeb;
      text-decoration: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    .navtab {
      margin-top: 2px;
      font-weight: bold;
      color: #ebebeb;
      text-decoration: none;
      line-height: 20px;
      margin-left: 60px;
      transition: all 0.3s;
      cursor: pointer;
      font-size: 20px;
      line-height: 20px;
      height: 24px;
      display: flex;
      align-items: flex-start;
      transition: all 0.3s;
      border-bottom: rgba(0, 0, 0, 0) 1px solid;
    }
    .navtab:hover {
      border-bottom: #dedede 1px solid;
      color: #dedede;
    }
  }
}
#footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 410px;
  align-items: center;
  justify-content: flex-start;
  background-color: #040000;
  color: #ebebeb;
  .logo {
    width: 1200px;
    margin-top: 36px;
    img {
      width: 185px;
      height: 56px;
    }
  }
  .main {
    width: 1200px;
    display: flex;
    margin-top: 24px;
    align-items: center;
    .left {
      flex: 1;
      .left-top {
        font-size: 16px;
        line-height: 16px;
      }
      .left-main {
        margin-top: 6px;
        line-height: 27px;
        font-size: 15px;
      }
    }
    .middo {
      display: flex;
      flex-direction: column;
      height: 94px;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        span {
          font-size: 15px;
          line-height: 15px;
        }
        input {
          width: 185px;
          height: 35px;
          outline-style: none;
          border: 0px;
          background-color: #1a1a1a;
          margin-right: 24px;
          padding: 0;
          color: #ccc;
        }
      }
    }
    .right {
      display: flex;
      span {
        font-size: 15px;
        line-height: 15px;
        margin-top: 9px;
      }
      textarea::-webkit-scrollbar {
        display: none; /*隐藏滚动条*/
      }
      textarea {
        width: 241px;
        height: 94px;
        outline-style: none;
        border: 0px;
        background-color: #1a1a1a;
        padding: 0;
        resize: none;
        color: #ccc;
      }
    }
  }
  .footer-nav {
    display: flex;
    width: 1200px;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    .navs {
      width: 525px;
      display: flex;
      justify-content: space-between;
      .navtab {
        margin-top: 2px;
        font-weight: bold;
        color: #ebebeb;
        text-decoration: none;
        line-height: 20px;
        transition: all 0.3s;
        cursor: pointer;
        font-size: 15px;
        line-height: 15px;
        height: 19px;
        display: flex;
        align-items: flex-start;
        transition: all 0.3s;
        border-bottom: rgba(0, 0, 0, 0) 1px solid;
      }
      .navtab:hover {
        border-bottom: #dedede 1px solid;
        color: #dedede;
      }
    }
    .imgs {
      width: 241px;
      display: flex;
      justify-content: space-between;
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.2s;
      }
      .wx {
        position: relative;
        border-radius: 50%;
        .wx-rem {
          transition: opacity 0.3s;
          display: none;
          opacity: 0;
          position: absolute;
          width: 100px;
          height: 100px;
          top: -108px;
          left: 50%;
          margin-left: -20px;
        }
      }
      .wx:hover {
        .icon {
          filter: brightness(1.4);
        }
        .wx-rem {
          display: inline-block;
          opacity: 1;
        }
      }
      .bili {
        position: relative;
        border-radius: 50%;
        .bili-rem {
          transition: opacity 0.3s;
          display: none;
          opacity: 0;
          position: absolute;
          width: 100px;
          height: 100px;
          top: -108px;
          left: 50%;
          margin-left: -50px;
        }
      }
      .bili:hover {
        .icon {
          filter: brightness(1.4);
        }
        .bili-rem {
          display: inline-block;
          opacity: 1;
        }
      }
      .dy {
        position: relative;
        border-radius: 50%;
        .dy-rem {
          position: absolute;
          transition: opacity 0.3s;
          display: none;
          opacity: 0;
          width: 100px;
          height: 100px;
          top: -108px;
          left: 50%;
          margin-left: -80px;
        }
      }
      .dy:hover {
        .icon {
          filter: brightness(1.4);
        }
        .dy-rem {
          display: inline-block;
          opacity: 1;
        }
      }
    }
  }
  .bottom {
    margin-top: 54px;
    color: #b4b4b4;
    font-size: 15px;
    line-height: 15px;
    a {
      color: #b4b4b4;
      font-size: 15px;
      line-height: 15px;
      text-decoration: none;
    }
  }
}
</style>
