import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
  message: {
    phrase: {
      home: '首页',
      products: '产品和服务',
      solutions: '解决方案',
      applications: '应用场景',
      news:'新闻动态',
      about: '关于我们',
      store: '数字资产',
      login: '登录',
    },
    setting: {
      system_preferences: '偏好设置',
      miniMize: '最小化到系统托盘',
      poweredUp: '开机启动',
      fullScreen: '启动时全屏显示'
    },
    info: {
      userinfo: '用户信息'
    },
    table: {
      item_number: "项目编号"
    },
    tabs: {
      virtual_space:'GBOX VS-实时虚拟渲染系统',
      XR_Set:'GBOX VS-XR沉浸式系统',
      AR_Set:'GBOX VS-AR虚拟植入系统',
      VR_Set:'GBOX VS-VR虚拟演播室系统',
      VPW:'GBOX VS–现场制作软件',
      Play_Controller:'GBOX VS-播出控制软件',
      Cloud_Rendering:'GBOX VS-实时云渲染系统',

      Vision_Palace:'GBOX VP-实时图文渲染系统',
      On_Air_Graphics:'GBOX VP-在线图文包装系统',
      Video_Wall_Graphics:'GBOX VP-大屏图文包装系统',
      AR_Set1:'GBOX VP-虚拟植入系统',
      VR_Set1:'GBOX VP-虚拟演播室系统',
      Engine:'GBOX VP-实时渲染引擎',
      Ultra_Designer:'GBOX VP-创作软件',
      Play_Controller1:'GBOX VP-播出控制软件',
      All_in_One_Box:'GBOX VP-全媒体虚拟演播一体机',
      Cloud_Rendering1:'GBOX VP-实时云渲染系统',

      Digital_Human:'数字人',
      Product_concept:'产品理念',
      Core_technology:'核心技术',
      Application_value:'应用价值',

      iV_LIVE:'iV LIVE',
      Digital_twinning:'数字孪生',
      Digital_Photography:'数字影楼',

      Technical_Support:'技术支持',
      Events_and_Programs_Support:'重大赛事和节目技术支持',
      Graphics_Creation_Service:'设计制作服务',
      Technical_Operation_Service:'技术运营服务'
    }
  },
  ...zhLocale
}

export default cn;