import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
//中文对应的英文
const en = {
  message: {
    phrase: {
      home: 'Home',
      products: 'Products & Service',
      solutions: 'Solutions',
      applications: 'Applications',
      news:'News',
      about: 'About',
      store: 'Store',
      login: 'Login',
    },
    setting: {
      system_preferences:'System Preferences',
      miniMize: 'Minimize to the system tray',
      poweredUp: 'Powered up',
      fullScreen: 'Full screen display at startup'
    },
    info: {
      userinfo: 'userinfo'
    },
    table: {
      item_number: "item number"
    },
    tabs: {
      virtual_space:'Virtual Space',
      XR_Set:'Virtual Space - XR Set',
      AR_Set:'Virtual Space - AR Set',
      VR_Set:'Virtual Space - VR Set',
      VPW:'Virtual Space - VPW',
      Play_Controller:'Virtual Space - Play Controller',
      Cloud_Rendering:'Virtual Space - Real-Time Cloud Rendering',

      Vision_Palace:'Vision Palace',
      On_Air_Graphics:'Vision Palace - On-Air Graphics',
      Video_Wall_Graphics:'Vision Palace - Video Wall Graphics',
      AR_Set:'Vision Palace - AR Set',
      VR_Set:'Vision Palace - VR Set',
      Engine:'Vision Palace - Engine',
      Ultra_Designer:'Vision Palace - Ultra Designer',
      Play_Controller:'Vision Palace - Play Controller',
      All_in_One_Box:'Vision Palace - All-in-One Box',
      Cloud_Rendering:'Vision Palace - Real-Time Cloud Rendering',

      Digital_Human:'Digital Human',
      Product_concept:'Product concept',
      Core_technology:'Core technology',
      Application_value:'Application value',
      Digital_twinning:'Digital twinning',
      iV_LIVE:'iV LIVE',

      Digital_Photography:'Digital Photography',

      Technical_Support:'Technical Support',
      Events_and_Programs_Support:'Events and Programs Support',
      Graphics_Creation_Service:'Graphics Creation Service',
      Technical_Operation_Service:'Technical Operation Service'
    }

  },
  ...enLocale
}

export default en;