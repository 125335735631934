import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import router from './router';
import store from './store'
import i18n from './utils/i18n/i18n'
import { Backtop, Button } from 'element-ui';
import Bus from '../src/bus' //这是我的路径，正确引用你们的路径
import 'lib-flexible/flexible'

Vue.config.productionTip = false
Vue.use(Backtop)
Vue.use(Button)
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
Vue.prototype.$bus = Bus
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
